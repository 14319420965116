import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import Link from "next/link";
import { useRouter } from "next/router";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";

const drawerWidth = 240;

const Menu = () => {
  const router = useRouter();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [search, setSearch] = React.useState<any>("");
  const [detailsSearch, setDetailsSearch] = React.useState<any>("");
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      router.push("/SearchResult?keyword=" + search);
    }
  };
  const handleSearch = () => {
    router.push("/SearchResult?keyword=" + search);
  };

  const handleKeyDownDetails = (event: any) => {
    if (event.key === "Enter") {
      router.push("/SearchDetailsResult?keyword=" + detailsSearch);
    }
  };
  const handleSearchDetails = () => {
    router.push("/SearchDetailsResult?keyword=" + detailsSearch);
  };

  const drawer = (
    <div>
      <List className="list-menu_mb">
        <ListSubheader component="div" id="nested-list-subheader">
          OTT SERVICE PORTAL
        </ListSubheader>
        <Link href="/">
          <ListItemButton
            sx={
              router.pathname === "/"
                ? {
                    backgroundColor: "#1565c0",
                    "&:hover": {
                      backgroundColor: "#000",
                    },
                  }
                : { backgroundColor: "rgba(11, 48, 142, 0.9)" }
            }
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </Link>
        <Link href="/NewProject">
          <ListItemButton
            sx={
              router.pathname === "/NewProject"
                ? {
                    backgroundColor: "#1565c0",
                    "&:hover": {
                      backgroundColor: "#000",
                    },
                  }
                : { backgroundColor: "rgba(11, 48, 142, 0.9)" }
            }
          >
            <ListItemIcon>
              <NoteAddIcon />
            </ListItemIcon>
            <ListItemText primary="New Project" />
          </ListItemButton>
        </Link>
        <Link href="/MyInformation">
          <ListItemButton
            sx={
              router.pathname === "/MyInformation"
                ? {
                    backgroundColor: "#1565c0",
                    "&:hover": {
                      backgroundColor: "#000",
                    },
                  }
                : { backgroundColor: "rgba(11, 48, 142, 0.9)" }
            }
          >
            <ListItemIcon>
              <PermContactCalendarIcon />
            </ListItemIcon>
            <ListItemText primary="My Information" />
          </ListItemButton>
        </Link>
      </List>
    </div>
  );

  return (
    <Box>
      <Box className="menu_bar" sx={{ display: { xs: "none", md: "flex" } }}>
        <div className="container">
          <Box className="row">
            <Grid container alignItems="center">
              <Grid item xs={12} md={6}>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={0}
                  sx={{ flexGrow: 1 }}
                >
                  <Link href="/">
                    <Button
                      className="btn-menu home"
                      sx={
                        router.pathname === "/"
                          ? {
                              width: 50,
                              height: 60,
                              backgroundColor: "#1565c0",
                              "&:hover": {
                                backgroundColor: "#000",
                              },
                            }
                          : { backgroundColor: "rgba(11, 48, 142, 0.9)" }
                      }
                    >
                      <HomeIcon />
                    </Button>
                  </Link>
                  <Link href="/NewProject">
                    <Button
                      className="btn-menu"
                      sx={
                        router.pathname === "/NewProject"
                          ? {
                              width: 200,
                              height: 60,
                              backgroundColor: "#1565c0",
                              "&:hover": {
                                backgroundColor: "#000",
                              },
                            }
                          : { backgroundColor: "rgba(11, 48, 142, 0.9)" }
                      }
                    >
                      <NoteAddIcon sx={{ mr: 2 }} />
                      New Project
                    </Button>
                  </Link>
                  <Link href="/MyInformation">
                    <Button
                      className="btn-menu"
                      sx={
                        router.pathname === "/MyInformation"
                          ? {
                              width: 200,
                              height: 60,
                              backgroundColor: "#1565c0",
                              "&:hover": {
                                backgroundColor: "#000",
                              },
                            }
                          : { backgroundColor: "rgba(11, 48, 142, 0.9)" }
                      }
                    >
                      <PermContactCalendarIcon sx={{ mr: 2 }} />
                      My Information
                    </Button>
                  </Link>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6} className="py-2">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography
                    sx={{ color: "#fff", mr: 2, fontWeight: "bold" }}
                    textAlign="left"
                  >
                    Issue Details Search
                  </Typography>

                  <Paper
                    className="searchInput"
                    sx={{
                      p: "2px 2px",
                      display: "flex",
                      alignItems: "center",
                      width: 300,
                      height: 40,
                      mr: 2,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Issue Details Search..."
                      inputProps={{ "aria-label": "Issue Details Search" }}
                      size="small"
                      value={detailsSearch}
                      onChange={(e: any) => {
                        setDetailsSearch(e.target.value);
                      }}
                      onKeyDown={handleKeyDownDetails}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      onClick={handleSearchDetails}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>

                  <Typography
                    sx={{ color: "#fff", mr: 2, fontWeight: "bold" }}
                    textAlign="left"
                  >
                    Serial No. Search
                  </Typography>

                  <Paper
                    className="searchInput"
                    sx={{
                      p: "2px 2px",
                      display: "flex",
                      alignItems: "center",
                      width: 300,
                      height: 40,
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Serial No. Search..."
                      inputProps={{ "aria-label": "Serial No. Search" }}
                      size="small"
                      value={search}
                      onChange={(e: any) => {
                        setSearch(e.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                      onClick={handleSearch}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Box>
      <Box className="menu_mb" sx={{ display: { xs: "flex", md: "none" } }}>
        <div className="container">
          <Grid container alignItems="center">
            <Grid item xs={2} className="py-0">
              <IconButton
                className="navigation"
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon />
              </IconButton>

              <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="menu"
              >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{
                    display: { xs: "block", md: "none" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                    "& .MuiPaper-root": {
                      backgroundColor: "#0b308e",
                    },
                  }}
                  className="DrawerMenu"
                >
                  {drawer}
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: "none", md: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                  open
                >
                  {drawer}
                </Drawer>
              </Box>
            </Grid>
            <Grid item xs={10} className="py-2 px-2">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <Typography sx={{ color: "#fff", mr: 2 }} textAlign="left">
                  Serial No. Search
                </Typography>
                <Paper
                  className="searchInput"
                  sx={{
                    p: "2px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: 300,
                    height: 40,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Serial No. Search..."
                    inputProps={{ "aria-label": "Serial No. Search" }}
                    size="small"
                    value={search}
                    onChange={(e: any) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                  />

                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={handleSearch}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Box>
  );
};

export default Menu;
