import React from "react";
import { styled } from "@mui/material/styles";
import Image from "next/image";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../public/static/images/logo.jpg";
import Logout from "@mui/icons-material/Logout";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { logout } from "@/features/login";
import { appDispatch, appSelector } from "@/store/hooks";
import { getSession } from "@/features/login";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

function stringToColor(string: string) {
  let hash = 0;
  let i;

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  if (name) {
    return {
      sx: {
        backgroundColor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}`,
      // children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }
}

function Header() {
  const { data, isAuthenticated, isAuthenticating } = appSelector(
    (state: any) => state.login_front
  );
  const [avatar, setAvatar] = React.useState<any>("");

  const dispatch = appDispatch();
  // if (isAuthenticating) {
  //   const fullname = await dispatch(getSession()).then((result: any) => {
  //     return result.fullname;
  //   });
  //   console.log(fullname);
  // }
  // const fullname = data.data.fullname;
  React.useEffect(() => {
    const fetchData = async () => {
      await dispatch(getSession()).then((result: any) => {
        setAvatar(result.payload.data.fullname);
      });
    };

    fetchData().catch(console.error);
  }, [avatar]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log("asdasdasdasd");
  };
  return (
    <AppBar position="static" id="back-to-top-anchor">
      <div className="container">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <Image
              src={logo}
              alt="Okuma Service Portal"
              className="logo_login"
              width={130}
            />
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "Open Sans",
              fontWeight: 800,
              letterSpacing: ".1rem",
              color: "#0b308e",
              textDecoration: "none",
            }}
          >
            OTT SERVICE PORTAL
          </Typography>

          <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
            <Image
              src={logo}
              alt="Okuma Service Portal"
              className="logo_login"
              width={100}
            />
          </Box>
          <Typography
            className="title-text"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 0,
              fontFamily: "Open Sans",
              fontWeight: 700,
              letterSpacing: "0",
              color: "#0b308e",
              textDecoration: "none",
            }}
          >
            OTT SERVICE PORTAL
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <ListItem alignItems="center">
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  className="avatar"
                  {...stringAvatar(avatar)}
                  sx={{ width: 50, height: 50 }}
                />
              </StyledBadge>
              <ListItemText>
                <Typography
                  className="user"
                  noWrap
                  component="a"
                  sx={{
                    display: "block",
                    ml: 1,
                    mb: 0,
                    flexGrow: 1,
                    fontFamily: "Open Sans",
                    fontWeight: 700,
                    letterSpacing: "0",
                    color: "#0b308e",
                    textDecoration: "none",
                    textAlign: "left",
                  }}
                >
                  {avatar}
                </Typography>
                <Link
                  onClick={() => dispatch(logout())}
                  className="list-logout"
                  href="#"
                  sx={{
                    display: "block",
                    ml: 1,
                  }}
                >
                  Log out <Logout />
                </Link>
              </ListItemText>
            </ListItem>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
              onClick={handleClick}
            >
              <Avatar
                className="avatar"
                {...stringAvatar(avatar)}
                sx={{ width: 50, height: 50 }}
              />
            </StyledBadge>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                mt: 1,
              }}
            >
              <MenuItem onClick={handleClose}>
                <Link
                  onClick={() => dispatch(logout())}
                  className="list-logout"
                  href="#"
                  sx={{
                    display: "block",
                    ml: 1,
                  }}
                >
                  <Logout sx={{ mr: 2 }} fontSize="small" /> Logout
                </Link>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </div>
    </AppBar>
  );
}

export default Header;
